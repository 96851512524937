import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/images/close-icon.png";
import styles from "./index.module.scss";
import viewMore from "../../assets/images/viewMore.svg";
import directions from "../../assets/images/directions.svg";
import backBtn from "../../assets/images/backBtn.png";
import share from "../../assets/images/share.svg";
import close from "../../assets/images/clsoe.svg";
import copy from "../../assets/images/copy.png";
import whatsapp from "../../assets/images/whatsapp.png";
import fb from "../../assets/images/fb.png";
import linkedin from "../../assets/images/linkedin.png";
import QRCode from "qrcode";
import { CafeList } from "./Foodmap";
import { isMobileDevice } from "../../utils/getPlatformInfo";
import ImageCarousel from "./ImageCarousel";

const searchTabs = [
    {
        name: "Registration",
        id: 6,
        type: "home",
    },
    {
        name: "Entry/Exit",
        id: 2,
        type: "home",
    },
    {
        name: "Venue Gates",
        id: 7,
        type: "home",
    },
    {
        name: "Cafeteria",
        id: 1,
        type: "home",
    },
    {
        name: "Parking",
        id: 3,
        type: "home",
    },
    {
        name: "Coffee Shops",
        id: 4,
        type: "hall",
    },
    {
        name: "Info Kiosk",
        id: 5,
        type: "hall",
    },
    // {
    //     name: "Center Square",
    //     id: 10,
    //     type: "hall",
    // },
    // {
    //     name: "Service Booth",
    //     id: 8,
    //     type: "hall",
    // },
    {
        name: "Washroom",
        id: 9,
        type: "hall",
    },
    // {
    //     name: "Innovate Seminar",
    //     id: 11,
    //     type: "hall",
    // },
    // {
    //     name: "Business Enter",
    //     id: 12,
    //     type: "hall",
    // },
    // {
    //     name: "Launch Pad",
    //     id: 13,
    //     type: "hall",
    // },
    // {
    //     name: "Elite Coffee",
    //     id: 14,
    //     type: "hall",
    // },
    {
        name: "Prayer Room",
        id: 15,
        type: "hall",
        hallId: ["hall5"]
    },
    {
        name: "Water Station",
        id: 16,
        type: "hall",
    },
    // {
    //     name: "Stationary Room",
    //     id: 17,
    //     type: "hall",
    // },
    {
        name: "Charging Station",
        // id: 18, //for mumbai site
        id: 17,//changed for banglore site
        type: "hall",
    }
];

const hiddenFilters = [
    6, 1
]

const initalImageUrl =
    "https://storage.googleapis.com/iijs-app.appspot.com/catalog";
var MAXLISTSIZE = 20;

const socialHandles = [
    { name: "whatsapp", src: whatsapp },
    { name: "fb", src: fb },
    { name: "linkedin", src: linkedin },
];

const Search = ({ handleSceneChange, scene, handleCanvasLoad, list, fuse, productsCategory, subProductsCategory }) => {
    const [searchList, setSearchList] = useState([]);
    const [filterType, setFilterType] = useState({ type: "home", id: null });
    const [showSearchIcon, setShowSearchIcon] = useState({
        search: true,
        close: false,
    });
    const [focused, setFocused] = useState(false);
    const [showDetailItem, setShowDetailItem] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [stallString, setStallString] = useState("");
    const [textInput, setTextInput] = useState("");
    const [lastSelected, setLastSelected] = useState({});
    const [directionMode, setDirectionMode] = useState(false);
    const [foodMenu, setFoodMenu] = useState("");
    const [highlightedTab, setHighlightedTab] = useState("")
    const [navigationView, setNavigationView] = useState(false);


    useEffect(() => {
        setSelectedItem({});
        setShowDetailItem(false);
        setStallString("");
        handleHomeBtnClick()
        setFilterType({ type: "home", id: null });
    }, [scene])

    useEffect(() => {
        if (shareModal) {
            generateQR();
        }
    }, [shareModal]);

    // const _handleCanvasLoad = () =>{
    //     setFilterType({ type: "home", id: null });
    //     handleCanvasLoad();
    // }

    const handleFoodMenu = (id) => {
        setFoodMenu(id);
    };

    const stallHighlightHandler = () => {
        setHighlightedTab("")
    }

    function goToStall(stallData) {
        window.parent.goToStall(true, stallData);
        handleViewMore(true, list[stallData.stallId]);
    }

    function replaceAllWordWithParticularCharacter(
        string,
        strReplace,
        strWith
    ) {
        // See http://stackoverflow.com/a/3561711/556609
        var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        var reg = new RegExp(esc, "ig");
        return string.replace(reg, strWith);
    }

    function goToStall3d(stallData) {
        handleHomeBtnClick()

        let id = stallData.stallId.split("Stall")[1].trim();

        if (id.toLowerCase().includes("jio")) {
            id = replaceAllWordWithParticularCharacter(id, "jio", "Jio");
            id = id.replace(/ /g, "_");
        } else if (id.startsWith("7")) {
            id = id.replace(/ /g, "").toUpperCase();
        } else {
            // replace with underscore
            id = id.replace(/ /g, "_").toUpperCase();
        }

        id = `Stall${id}`;
        let _stallData = { stallId: id }
        console.log(id, " : goToStall3d")
        if (scene.toLowerCase() == list[_stallData.stallId].venue) {
            goToStall(_stallData)
        } else {
            handleSceneChange(list[_stallData.stallId].venue.toUpperCase(), true, goToStall, _stallData)
        }
    }

    useEffect(() => {
        window.parent.goToStall3d = goToStall3d;
        window.parent.frontEndLinker = {
            handleCanvasLoad: handleCanvasLoad,
            // closeSearchResult: handleSearchCloseClick,
            // openMediaModal: openMediaModal,
            // closeMediaModal: closeMediaModal,
            viewMore: handleViewMore,
            closeDetailsPop: closeDetailsPop,
            menuOpen: handleFoodMenu,
            deactivateFilterBtn: deactivateFilterBtn,
            updateStartingpointName: updateStartingpointName,
            hallOpen: handleHallOpen,
            hallClose: handleHallClose,
            stallHighlightHandler: stallHighlightHandler,
        };
    }, []);

    const deactivateFilterBtn = () => {
        console.log("dirrs")
    }
    const handleHallClose = () => {
        setHighlightedTab("")
        if (foodMenu) {
            setFoodMenu("")
        }
        setFilterType({ type: "home", id: null });
    };

    const handleHallOpen = (hallId) => {
        setHighlightedTab("")
        if (foodMenu) {
            setFoodMenu("")
        }
        setFilterType({ type: "hall", id: hallId });

    };

    const updateStartingpointName = (name) => {
        setTextInput(name);
    };

    const searchWord = (word, filter1 = [], filter2 = []) => {

        let filterArr1 = filter1.map((v) => ({ product_dealing: v }));
        let filterArr2 = filter2.map((v) => ({ "sub category": v }));
        // let filterArr3 = [{ venue: scene.toLowerCase() }]
        let or = [
            "Exhibitor_Name",
            "Exhibitor_StallNo1",
            "Exhibitor_Code",
            "Exhibitor_Section",
            "Exhibitor_Brand_Name",
            "sub category",
            "Any Other Product Category",
            "Any other Sub Product Category",
            "product_dealing",
            "Brands",
            "Search_Name"
        ].map((k) => ({ [k]: word.replaceAll(" ","") }));

        let a = fuse.search({
            $and: [
                {
                    $or: or,
                },
                ...filterArr1,
                ...filterArr2,
                // ...filterArr3
            ],
        });
        return a;
    };

    function searchThis(pattern) {
        // if (searchReady)
        return searchWord(
            pattern,
            Object.keys(productsCategory).filter((id) => productsCategory[id]),
            Object.keys(subProductsCategory).filter((id) => subProductsCategory[id])
        );
        // else return null;
    }

    function showSearchResults(value, forNav = false) {
        // if (!populatedList.classList.contains("showPList")) {
        //   populatedList.classList.add("showPList");
        // }
        // if (navigationMode) {
        //   populatedList.classList.add("navmodeSelectlist");
        // }

        // var temp = document.getElementById("populatedList");
        // var searchEles = temp.children;

        // for (var i = searchEles.length - 1; i >= 0; i--)
        //   searchEles[i].parentNode.removeChild(searchEles[i]);

        var __searchList = searchThis(value);
        setSearchList(__searchList.splice(0, MAXLISTSIZE));
        // if (searchList.length > 0) {
        //   searchList = searchList.splice(0, MAX_LIST_SIZE);
        //   if (!forNav) {
        //     lastSelected = null;
        //   }
        //   for (var i = 0; i < searchList.length; i++) {
        //     itemClone(i, temp, searchList[i].item, forNav);
        //   }
        // } else {
        //   if (!forNav) {
        //     lastSelected = null;
        //   }
        //   for (var i = 0; i < list.length; i++) {
        //     itemClone(i, temp, list[i], forNav);
        //   }
        // }
    }

    const handleChange = (e) => {
        //   viewMoreBackBtn();
        if (foodMenu) {
            setFoodMenu("")
        }
        if (e.target.value) {
            setShowSearchIcon({
                search: false,
                close: true,
            });
            showSearchResults(e.target.value);
            setTextInput(e.target.value);
        } else {
            setShowSearchIcon({
                search: true,
                close: false,
            });
            clearTextField();
        }
    };
    const homeBtnClick = () => {
        console.log("first");
    };

    const handleViewMore = (e, item) => {
        if (foodMenu) {
            setFoodMenu("")
        }
        if (directionMode) {
            handleExtend(item);
            return;
        }
        setShowDetailItem(true);
        setSelectedItem(item);
        let stallCount = 0;
        const keyValues = ["Exhibitor_StallNo1"];
        let _stallString = "";

        // , "Exhibitor_StallNo2", "Exhibitor_StallNo3", "Exhibitor_StallNo4", "Exhibitor_StallNo5", "Exhibitor_StallNo5"]
        keyValues.forEach((key) => {
            if (item[key]) {
                stallCount++;
                if (stallCount > 1) {
                    _stallString += ", " + item[key];
                } else {
                    _stallString += item[key];
                }
                setStallString(_stallString);
            }
        });
    };

    const handleDirections = (item) => {
        setFocused(true);
        setShowDetailItem(false);
        setLastSelected(item);
        setDirectionMode(true);
        setSearchList([]);
        setTextInput("");
        setSelectedItem({});
    };

    const handleShare = (id) => {
        console.log(id);
    };

    const handleExtend = (item) => {
        if (directionMode) {
            window.parent.nav.navigateBetweenStalls(
                { ...list[item.StallId__1], stallId: item.StallId__1 },
                { ...list[lastSelected.StallId__1], stallId: lastSelected.StallId__1 },
            );
            setNavigationView(true);
            setSearchList([]);
            setTextInput(item.Exhibitor_Name);
            // setFocused(false)
            return;
        }
        setSelectedItem(item);
        if (typeof window.parent.goToStall !== "undefined") {
            item.stallId = item.StallId__1;
            // item.imgURL =
            //     initalImageUrl +
            //     "/" +
            //     item.Exhibitor_Code +
            //     "/" +
            //     item.Catalog_CompanyLogo;
            item.imgURL =
                item.Catalog_CompanyLogo;

            item.stallName = "" + item.Exhibitor_Name;
            window.parent.goToStall(true, item);
        }
    };

    const handleHomeBtnClick = () => {
        setFilterType({ type: "home", id: null });

        setHighlightedTab("")
        if (directionMode) {
            exitDirections();
        } else {
            if (typeof window.parent.goToHome !== "undefined") {
                window.parent.goToHome((res) => {
                    console.log(res, "Resss")
                    // if (tabname) {
                    //     additionalFiltersTabClick(
                    //         DIRECT_KEYS[tabname].CHILDINDEX,
                    //         DIRECT_KEYS[tabname].TABINDEX,
                    //         true
                    //     );
                    // }
                });
            }

            // activateOutsideFilters();
            // if (!additional) {
            //   additionalFiltersTabClick(0);
            // }
            handleBack();
            // handleSearchCloseClick();
            // exitDirections();
            // if (typeof window.parent.highlightTab !== "undefined")
            //     if (!additional) {
            //         window.parent.highlightTab(0, false);
            //     }
        }
    };

    const clearTextField = () => {
        if (directionMode) {
            exitDirections();
        }
        setTextInput("");
        setShowSearchIcon({
            search: true,
            close: false,
        });
        setSearchList([]);
        setFilterType({ type: "home", id: null });
        handleBack();
    };

    const closeDetailsPop = () => {
        setSelectedItem({});
        setShowDetailItem(false);
        setStallString("");
    };

    const handleBack = () => {
        setHighlightedTab("")
        if (typeof window.parent.goToHome !== "undefined") {
            window.parent.getBackOneLevel((red) => {
                console.log(red, "red")
            });
            // setFilterType({ type: "home", id: null });
        }

        closeDetailsPop();
    };

    const handleModal = () => {
        setShareModal((prev) => !prev);
    };

    const exitDirections = () => {
        if (textInput) {
            window.parent.nav.exitNavigation();
        }
        setShowSearchIcon({
            search: true,
            close: false,
        });
        handleBack();
        setDirectionMode(false);
        setTextInput("");
        setFocused(false);
        setNavigationView(false);
    };

    const additionalTabsClick = (e) => {
        updateStartingpointName("");
        setSearchList([]);
        setShowDetailItem(false)
        if (directionMode) {
            exitDirections()
        }
        if (typeof window.parent.highlightTab !== "undefined")
            if (highlightedTab == e) {
                window.parent.highlightTab(e, false);
                setHighlightedTab("")

            } else {
                window.parent.highlightTab(e, true);
                setHighlightedTab(e)

            }

        //   if (stallInput.disabled) {
        //     navigationMode = false;
        //     exitDirections();

        //   lastActiveNumber = e;
        //   lastActiveTab = filtersDiv.children[childIndex];
        //   filtersDiv.children[childIndex].classList.add("activeTab");
        // }
        // handleSearchCloseClick();
        // handleBack();
    };

    const generateQR = () => {
        var canvas = document.getElementById("canvas");

        QRCode.toCanvas(canvas, "sample text", function (error) {
            if (error) console.error(error);
            console.log("success!");
        });
    };

    return (
        <div className={styles.tabContainer}>
            <form
                className={styles.searchForm}
                style={
                    isMobileDevice && navigationView
                        ? { bottom: "10vh" }
                        : focused && isMobileDevice
                            ? directionMode && searchList.length == 0
                                ? { bottom: "40vh" }
                                : { bottom: "50vh", width: "20rem" }
                            : { width: "20rem" }
                }
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className={styles.searchInput}
                    style={
                        directionMode
                            ? {
                                padding: "0 20px 0 50px",
                                boxSizing: "border-box",
                                width: "calc(100% + 47px)",
                            }
                            : {}
                    }>
                    {directionMode && <span>From :</span>}
                    <input
                        value={textInput}
                        type="text"
                        onFocus={() => setFocused(true)}
                        onBlur={() => searchList.length == 0 && setFocused(false)}
                        style={
                            directionMode
                                ? {
                                    width: "80%",
                                    minWidth: "80%"
                                }
                                : {
                                    width: "90%",
                                    minWidth: "90%"
                                }
                        }
                        onChange={handleChange}
                        id="search-bar"
                        name="myInput"
                        autocomplete="off"
                        placeholder="Looking for something?"
                    />
                </div>
                {directionMode && (
                    <>
                        {" "}
                        <img
                            className={styles.blueBack}
                            src="/textures/blueBack.svg"
                            onClick={exitDirections}
                        />
                        <div className={styles.searchInput2}>
                            <span>To :</span>
                            <input
                                value={lastSelected.Exhibitor_Name}
                                type="text"
                                disabled={true}
                            />
                        </div>
                        <img
                            className={styles.searchClose2}
                            src={closeIcon}
                            onClick={clearTextField}
                        />
                    </>
                )}
                {showSearchIcon.search && !directionMode && (
                    <img
                        className={styles.searchIcon}
                        src="https://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png"
                    />
                )}
                {showSearchIcon.close && !directionMode && (
                    <img
                        className={styles.searchClose}
                        src={closeIcon}
                        onClick={clearTextField}
                    />
                )}
                <div
                    style={
                        isMobileDevice
                            ? searchList.length > 0 || Object.values(selectedItem).length > 0
                                ? directionMode
                                    ? { height: "40vh" }
                                    : { height: "50vh" }
                                : {}
                            : {}
                    }
                    className={styles.populatedList}
                >
                    {!showDetailItem &&
                        searchList.map((_item) => {
                            const item = _item.item;
                            return (
                                <>
                                    <div
                                        onClick={() => handleExtend(item)}
                                        className={styles.listChild}
                                    >
                                        {!showDetailItem && (
                                            <>
                                                <div style={{ width: "20%" }}>
                                                    <img
                                                        style={{ width: "100%", height: "100%" }}
                                                        src="https://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png"
                                                    />
                                                </div>
                                                <div className={styles.exhibhitorDesc}>
                                                    <div>{item.Exhibitor_Name}</div>
                                                    <div className={styles.exhibitorSubDesc}>
                                                        <div>{"Stall no. " + item.Exhibitor_StallNo1}</div>
                                                        <div>{"Hall " + item.Exhibitor_DivisionNo}</div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: selectedItem == item ? "6vh" : "0",
                                                        }}
                                                        className={styles.catalogBrief}
                                                    >
                                                        {item.Catalog_Brief}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {selectedItem == item && (
                                        <div className={styles.btnsWrapper}>
                                            <img
                                                onClick={(e) => handleViewMore(e, item)}
                                                src={viewMore}
                                            />
                                            <img
                                                onClick={() => handleDirections(item)}
                                                src={directions}
                                            />
                                        </div>
                                    )}
                                </>
                            );
                        })}
                    {showDetailItem && selectedItem && (
                        <div className={styles.itemViewMore}>
                            <div className={styles.detailInfoStickyHeader}>
                                <img onClick={handleBack} src={backBtn} />
                                <div className={styles.rightBtns}>
                                    <img
                                        onClick={() => handleDirections(selectedItem)}
                                        src={directions}
                                    />
                                    {/* <img
                                        onClick={handleModal}
                                        className={styles.shareBtn}
                                        src={share}
                                    /> */}
                                </div>
                            </div>

                            <ImageCarousel data={selectedItem.Catalog_ProductLogo} />
                            <div className={styles.infoContainer}>
                                <div className={styles.title}>
                                    {selectedItem.Exhibitor_Name}
                                </div>
                                <table>
                                    {selectedItem.Exhibitor_Section && (
                                        <tr>
                                            <td><b>Section</b></td>
                                            <td>{`${selectedItem.Exhibitor_Section}`}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td><b>Stall No. </b></td>
                                        <td>{`${stallString}`}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Hall No. </b></td>
                                        <td>{`${selectedItem.Exhibitor_Hall}`}</td>
                                    </tr>
                                </table>
                                {/* <div><b></b> {}</div> */}
                                {/* <div>{"Hall " + selectedItem.}</div> */}
                                <div className={styles.extraInfoCatalog}>
                                    {selectedItem.Catalog_Brief}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!navigationView &&
                    isMobileDevice &&
                    searchList.length == 0 &&
                    focused && (
                        <div
                            style={{ height: directionMode ? "30vh" : "50vh" }}
                            className={styles.emptyList}
                        ></div>
                    )}
            </form>
            {shareModal && (
                <>
                    <div onClick={handleModal} className={styles.modalOverlay}></div>
                    <div className={styles.shareModal}>
                        <div className={styles.close}>
                            <img src={close} onClick={handleModal} className="btn" />
                        </div>
                        <div className={styles.title}>Share</div>
                        <canvas className={styles.canvas} id="canvas" />
                        <div className={styles.linkWrapper}>
                            <div id={styles.link}></div>
                            <img src={copy} id="copy" className="btn" />
                        </div>
                        <div className={styles.socialHandles}>
                            {socialHandles.map((social) => {
                                return (
                                    <img
                                        src={social.src}
                                        onClick={handleShare(social.name)}
                                        className="btn"
                                    />
                                );
                            })}
                        </div>
                    </div>
                </>
            )}

            <div className={styles.filtersDiv}>
                <img
                    src="/textures/home.svg"
                    className={styles.btn}
                    style={{ margin: "0vh 1vh" }}
                    onClick={handleHomeBtnClick}
                />
                {searchTabs
                    .filter((item) => item.type == filterType.type)
                    .map((tab) => {
                        if (filterType.type == "home" && scene == "JIO") {
                            if (hiddenFilters.includes(tab.id)) {
                                return
                            }
                        }
                        if(filterType.type != "home" && tab.hallId != null && !tab.hallId.includes(filterType.id)){
                            return
                        }
                        return (
                            <div
                                style={tab.id == highlightedTab ? { background: "#000", color: "#fff" } : {}}
                                onClick={() => additionalTabsClick(tab.id)}
                                className={styles.filtersTab}
                            >
                                {tab.name}
                            </div>
                        );
                    })}
            </div>
            {foodMenu && (
                <>
                    <div onClick={() => setFoodMenu("")} className={styles.foodmenuOverlay}></div>
                    <div className={styles.foodMenuWrapper}>
                        <div className={styles.header}>FOOD MENU</div>
                        {CafeList[foodMenu]()}
                    </div>
                </>
            )}
        </div>
    );
};

export default Search;
