import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import Fuse from 'fuse.js';
import Search from '../Search';
import styles from "./index.module.scss"
import logo1 from "../../assets/images/gjepc-logo.png"
import logo2 from "../../assets/images/Got-karnataka-black.png"
import { isMobileDevice } from '../../utils/getPlatformInfo';
import dropdown from "../../assets/images/dropdown.png"

const stallJsonLinks = [
    {
        path: "./stallsData/IIJS_tritiya2024.json",
        name: "hall1",
    },
];




const inputs = {
    productsCategory: [
        { name: "Antique Jewellery", type: "productsCategory", id: "antique" },
        { name: "Diamond Gemstones Jewellery", type: "productsCategory", id: "diamond" },
        { name: "Kundan Jewellery", type: "productsCategory", id: "kundan" },
        { name: "Lab Grown Diamond", type: "productsCategory", id: "kundan" },
        { name: "Loose Diamond", type: "productsCategory", id: "kundan" },
        { name: "Pearls", type: "productsCategory", id: "kundan" },
        { name: "Plain Gold Jewellery", type: "productsCategory", id: "kundan" },
        { name: "Platinum Jewellery", type: "productsCategory", id: "kundan" },
        { name: "Silver Jewellery", type: "productsCategory", id: "kundan" },
    ],
    subProductsCategory: [
        { name: "Ancle Bracelets(Payal)", type: "subProductsCategory", id: "bangles" },
        { name: "Bangles", type: "subProductsCategory", id: "bangles" },
        { name: "Bracelets", type: "subProductsCategory", id: "bracelet" },
        { name: "Chains", type: "subProductsCategory", id: "chains" },
        { name: "Designer Jewellery", type: "subProductsCategory", id: "chains" },
        { name: "Earrings", type: "subProductsCategory", id: "chains" },
        { name: "Loose Diamonds", type: "subProductsCategory", id: "chains" },
        { name: "Mangalsutra", type: "subProductsCategory", id: "chains" },
        { name: "Necklaces", type: "subProductsCategory", id: "chains" },
        { name: "Pendants", type: "subProductsCategory", id: "chains" },
    ]
}


const totalJsonToBeloaded = stallJsonLinks.length;

let listForFuse = null;
// var fuse = null;
const options = {
    // isCaseSensitive: false,
    includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 2,
    // location: 0,
    threshold: 0.2,
    // distance: 100,
    useExtendedSearch: true,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: [
        "Exhibitor_Name",
        "Exhibitor_StallNo1",
        "Exhibitor_Code",
        "Exhibitor_Section",
        "Exhibitor_Brand_Name",
        "sub category",
        "Any Other Product Category",
        "Any other Sub Product Category",
        "product_dealing",
        "Brands",
        // "venue"
    ],
};



const scenes = [
    { name: "JIO", label: "JWCC" },
    { name: "NESCO", label: "BEC" },
]


const Home = () => {
    const [canvasLoaded, setCanvasLoaded] = useState(true)
    const [list, setList] = useState(null)
    const [searchReady, setSearchReady] = useState(false)
    const fuse = useRef(null)
    const [productsCategory, setProductsCategory] = useState({})
    const [subProductsCategory, setsubProductsCategory] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [scene, setScene] = useState("JIO")
    const [loading, setLoading] = useState(false)
    const [jsonloadCount, setJsonloadCount] = useState(0)
    const [allJsonLoaded, setAllJsonLoaded] = useState(false)
    const [showStall, setShowStall] = useState(false)
    const [goToStallId, setgoToStallId] = useState(false)
    const goToStallRef = useRef(null)
    const [disabled, setDisabled] = useState(false)



    const handleSceneChange = (name, params = null, goToStall = null, stallData = null) => {
        if (name == scene) {
            return
        }
        if (disabled) {
            return
        }
        setDisabled(true)
        setLoading(true)
        setJsonloadCount(0)
        setScene(name)
        window.parent.changeScene(name, () => {
            if (params) {
                if (goToStall) {
                    setgoToStallId(stallData)
                    goToStallRef.current = goToStall
                } else {
                    window.parent.highlightSingleCard(params.itemid, params.cardid, params.hallName, true)
                }
            }
            setDisabled(false)
        })
    }


    const handleChange = (e, type) => {
        if (type == "productsCategory") {
            setProductsCategory(prev => ({
                ...prev,
                [e.target.name]: e.target.checked
            }))
        } else {
            setsubProductsCategory(prev => ({
                ...prev,
                [e.target.name]: e.target.checked
            }))
        }
    }

    const handleAllJsonDataLoaded = () => {
        if (list != null) {
            setSearchReady(true)
            listForFuse = Object.values(list);
            fuse.current = new Fuse(listForFuse, options); //withOutIndex
            // if (canvasLoaded) {
            //     toggleCircularLoader(false);
            // }
        }
    };

    const handleSingleJsonLoad = (name) => {
        if (totalJsonToBeloaded == jsonloadCount + 1) {
            setAllJsonLoaded(true)
            handleAllJsonDataLoaded();
            setJsonloadCount(prev => prev + 1)
            setLoading(false)
        }
    };

    useEffect(() => {
        if (list && Object.values(list).length > 0) {
            if (goToStallId) {
                goToStallRef.current(goToStallId)
            }
            setLoading(true)
            handleSingleJsonLoad()
        }
    }, [list])


    const handleJsonData = (name, data) => {
        if (data) {
            //put in global list
            setList(prev => ({
                ...prev,
                ...data
            }))
            if (window.parent.sendAllDataToPlaycanvas) {
                window.parent.sendAllDataToPlaycanvas(data);
                try {
                    window.ReactNativeWebView.postMessage("loaded");
                } catch (err) {
                    console.log("first");
                }

                //
                // if (decodeURI(window.location.hash)) {
                //   stallData = {
                //     stallId: decodeURI(window.location.hash.split("#")[1]),
                //   };

                //   window.parent.goToStall(true, stallData);
                //   viewMore(true, list[stallData.stallId]);
                //   history.replaceState(
                //     "",
                //     document.title,
                //     window.location.origin +
                //     window.location.pathname +
                //     window.location.search
                //   );
                // }
            } else {
                console.log("playcanvas function not found to load json");
            }
        } else {
            console.log("errrooorr");
        }
        setCanvasLoaded(true)

        setLoading(false)
    };


    const loadAllJsons = () => {
        stallJsonLinks.forEach(async ({ path, name }) => {
            const response = await fetch(path
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    return response.json()

                })
                .catch(err => {
                    console.log(err)
                })

            handleJsonData(name, response);
            // handleSingleJsonLoad(name);
        });
    };

    const handleCanvasLoad = () => {
        loadAllJsons();
        // toggleCircularLoader(!allJsonLoaded);
    }

    const handleSingleTab = (itemid = 4, cardid = 1, hallName = -1, _scene = "NESCO") => {

        if (scene === _scene) {
            window.parent.highlightSingleCard(itemid, cardid, hallName, true)
        } else {
            let params = {
                itemid,
                cardid,
                hallName,
                _scene
            }
            handleSceneChange(_scene, params)
        }
    }


    useEffect(() => {
        window.parent.singleTab = handleSingleTab
    }, [scene])


    useEffect(() => {
        window.parent.frontEndLinker = {
            handleCanvasLoad: handleCanvasLoad,
        };
    }, [])

    const handleModalClose = () => {
        setShowFilter(false)
    }


    return (
        <div>
            {loading && <div className={styles.loading}>
                <div className="lds-dual-ring"></div></div>}
            {!isMobileDevice && <div className={styles.logoWrapper}>
                <img
                    className={styles.logo}
                    src={logo1}
                    style={{
                        top: "0px",
                        right: "0px",
                        position: "absolute",
                        width: "125px",
                        margin: "20px 0px",
                        zIndex: 2
                    }}
                />
                {/* <img
                    src={logo2}
                    style={{
                        backgroundColor: "#fff",
                        padding: "8px",
                        borderRadius: "5px",
                        boxSizing: "border-box",
                        top: "70px",
                        right: "0px",
                        position: "absolute",
                        width: "125px",
                        margin: "20px 0px",
                        zIndex: 2
                    }}
                /> */}
            </div>}
            {/* {list && <div className={styles.switchScenes}>
                {scenes.map(_scene => {
                    return <div onClick={() => handleSceneChange(_scene.name)} style={_scene.name === scene ? { background: "#000", border: "1px solid #fff", color: "#fff" } : {}} className={styles.switchBtn}>{_scene.label}</div>
                })}
            </div>} */}
            {list && <div className={styles.dropdown}>{(Object.keys(productsCategory).filter((id) => productsCategory[id]).length > 0 ||
                Object.keys(subProductsCategory).filter((id) => subProductsCategory[id]).length > 0) && <div className={styles.filterIcon}></div>}<img onClick={() => setShowFilter(true)} src={dropdown} className={styles.dropdownSvg} /></div>}
            {showFilter &&
                <><div onClick={() => setShowFilter(false)} className={styles.modalOverlay}></div><div className={styles.modal}>
                    <div style={{ maxHeight: "50vh", overflow: "auto" }}>
                        <div className={styles.headname}>Products</div>
                        {inputs.productsCategory.map(input => {
                            return <div className={styles.checkboxWrapper}><input type="checkbox" className={styles.checkbox} name={input.name} checked={productsCategory[input.name]} onChange={(e) => handleChange(e, "productsCategory")} /><label>{input.name}</label></div>
                        })}
                        <div className={styles.headname}>Sub Products</div>
                        {inputs.subProductsCategory.map(input => {
                            return <div className={styles.checkboxWrapper}><input type="checkbox" className={styles.checkbox} name={input.name} checked={subProductsCategory[input.name]} onChange={(e) => handleChange(e, "subProductsCategory")} /><label>{input.name}</label></div>
                        })}
                    </div>
                    <button onClick={handleModalClose} className={styles.apply}>Apply</button>
                </div></>}
            {list && <Search showStall={showStall} handleSceneChange={handleSceneChange} scene={scene} handleCanvasLoad={handleCanvasLoad} productsCategory={productsCategory} subProductsCategory={subProductsCategory} list={list} fuse={fuse.current} />}
        </div>
    )
}

export default Home